import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import ContactIcon from "@material-ui/icons/Chat";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {
  handleLocation,
  isValidLocation,
  getCurrentLocation,
} from "../utils/checkValidLocation";
import { LinearProgress } from "@material-ui/core";
import Shutdown from "../components/Shutdown";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  header: {
    margin: theme.spacing(3, 0, 2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ContactUs() {
  const classes = useStyles();
  const [location, setLocation] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let currentOrigin = window?.location?.origin || "vusiem";
    if (
      currentOrigin.toLowerCase().includes("vusiem") ||
      currentOrigin.toLowerCase().includes("museum-buddy")
    ) {
      let currentLocation = getCurrentLocation();
      if (currentLocation?.location) {
        setLocation(currentLocation.location);
        setLoading(false);
      } else {
        var requestOptions = {
          method: "GET",
        };

        fetch(
          "https://api.geoapify.com/v1/ipinfo?&apiKey=3db745ec903945059d33e698d06ffd17",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            handleLocation({ locationCode: result?.country?.iso_code });
            if (result?.country?.iso_code !== "DE") {
              setLocation(result?.country?.iso_code);
            }
            setLoading(false);
          })
          .catch((error) => {
            handleLocation({ locationCode: "US" });
            setLocation("US");
            setLoading(false);
          });
      }
    } else {
      setLocation("US");
      setLoading(false);
    }
  }, []);

  return loading ? (
    <LinearProgress />
  ) : location ? (
    <Layout>
      <SEO
        title="Contact Us @ Museum Buddy"
        description={
          "Get in touch with us with any queries or enquiries. We usually respond within a few hours!"
        }
      />
      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <div className={classes.paper}>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 1 }}
          >
            <Avatar className={classes.avatar}>
              <ContactIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className={classes.header}>
              Contact us at Museum Buddy
            </Typography>
          </div>
          <Typography component="h2" variant="body2" className={classes.header}>
            Please get in touch with us for any queries or enquiries that you
            might have. Please ensure that you include your contact details
            correctly so that we can revert back to you for any clarifications.
          </Typography>

          <div>
            {/* <iframe title='Contact Form' frameborder="0" style={{height: '1200px', width: '99%', border: 'none'}} src='https://forms.zohopublic.com/business1947/form/ContactForm/formperma/C6hVTnlARhgd8olKH-VsN_aH2OMxH7SnPpuPgusJH7g'></iframe> */}

            <iframe
              id="JotFormIFrame-230547245845359"
              title="Contact us @ Museum Buddy."
              onload="window.parent.scrollTo(0,0)"
              allowtransparency="true"
              allowfullscreen="true"
              allow="geolocation; microphone; camera"
              src="https://form.jotform.com/230547245845359"
              frameborder="0"
              style="min-width:100%;max-width:100%;height:939px;border:none;"
              scrolling="yes"
            ></iframe>
          </div>
          {/*<form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>

            <TextField
              variant="outlined"
              required
              fullWidth
              id="phone"
              label="Contact number"
              name="phone"
              autoComplete="phone"
            />
            </Grid>
            <Grid item xs={12}>

            <TextField
            id="message"
          label="Message"
          multiline
          rows="6"
          defaultValue="Default Value"
          variant="outlined"
              fullWidth
              required
            />
            </Grid>

            </TextField>


          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send
          </Button>

        </form>*/}
        </div>
      </Container>
    </Layout>
  ) : (
    <Shutdown />
  );
}
